.notFocusShadow:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .dropdown > .dropdown-item:hover, .dropdown > .dropdown-item:focus {
    background-color: #ffffff !important;
 }

.CustomdropDown:focus, .CustomdropDown:hover{
  background-color: #ffffff !important;
}


.dropdown-backdrop {
  display:block;
}
.fondologin{

  background-image: url("./assets/images/FondoPrincipal.jpg");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;


}
.preguntaderecha {
  background-image: url("./assets/images/preguntacierre.png");
  background-size: 15% 85%;
  background-repeat:no-repeat;
  background-position:center;
}
.prcoment {
  background-image: url("./assets/images/cierreinclinado.png");
  background-size: 10% 95%;
  background-repeat:no-repeat;
  background-position:right;
}
.pracoment {
  background-image: url("./assets/images/aperturainclinado.png");
  background-size: 10% 95%;
  background-repeat:no-repeat;
  background-position:left;
}
.fondoRespuestas {
  /* background-image: url("./assets/images/FondoRespuestas.png");
  background-repeat: no-repeat; */
  background: url("./assets/images/FondoRespuestas.png") center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.cardback {
  align-items: center;
  background-color: #001e4f;
  /* background-color: rgba(0, 30, 79, .5); COLOR COPE */
  background-color: rgba(0, 0, 0, .3);
  color: white;
  border-radius: 20px;
  padding: 40px;
}
.subrayado {
  border-bottom: 3px solid #1c9d2e;
}
.line-clamp {
  width:70%;
}
.cardContent {
  color: white;
}

.card-left-new{
  margin-left: 15px;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
  max-height: 200px;
  z-index: 2;
}

.card-right-new{
  z-index: 3;
  box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 20px 10px !important;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  min-height: 200px;
  margin-top: 7px;
  margin-left: -40px; 
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
}